//export const OS_GALILEO_STORY_HEADER_TEXT = `the galileo story`;
//export const OS_GALILEO_STORY_PARA_TEXT = `Started in 1945 by Frank Sorba in the San Francisco Bay Area, Galileo® Salame was founded on tradition. More than 75 years later, we have stayed true to the taste and quality that made us a success. We still craft our Italian meats in the Bay Area where the temperate climate, similar to that of northern Italy, makes the location ideal to cure salame and develop its deliciously tangy flavor profile.`;

export const OS_FLAVOR_SAVOR_HEADER_TEXT = `a flavor to savor`;
export const OS_FLAVOR_SAVOR_PARA_TEXT = `Our process starts with high-quality pork. We craft our salame with lean hand-trimmed pork, giving it mouth-watering flavor and a unique texture. A difference you can taste. Our timeless recipe uses a robust herb and spice blend that excites with every bite. For over 75 years, we’ve been making authentic Italian salame from a process passed down from generation to generation to be enjoyed by all.`;

export const OS_SCHOOL_METHOD_HEADER_TEXT = `old-school method`;
export const OS_SCHOOL_METHOD_PARA_TEXT = `Authentic Italian flavor each and every time. First, our salame is stuffed into its natural casing and sent to the culture room to ferment. After 72 hours, the salame enters the drying room. Here the salame dries for several weeks where it develops an abundance of rich flavor. Our artisans delicately watch each individual salame chub to ensure curing perfection.`;

export const OS_DELI_QUALITY_HEADER_TEXT = `deli quality`;
export const OS_DELI_QUALITY_PARA_TEXT = `As we grew over the years, we made sure to maintain the original recipe while making advancements to the manufacturing process that enabled us to bring Deli quality salame and pepperoni to grocery stores everywhere. Our meats are sliced and sealed with care for you and your family to enjoy.`;