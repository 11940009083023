// import node modules
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { navigate, Link } from "gatsby";

// import components
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout.component";

//import images
import os_bg_one from "../assets/images/ourStory/original-storefront.png";
import os_bg_two from "../assets/images/ourStory/sliced-salame.png";
import os_bg_three from "../assets/images/ourStory/hanging-salame.png";
import os_bg_four from "../assets/images/ourStory/deli-storefront.png";
import pepperoni_salame from "../assets/images/products/pepperoni.png";
import salame from "../assets/images/products/italian-dry-salame.png";

import {
  OS_GALILEO_STORY_PARA_TEXT,
  OS_FLAVOR_SAVOR_HEADER_TEXT,
  OS_FLAVOR_SAVOR_PARA_TEXT,
  OS_SCHOOL_METHOD_HEADER_TEXT,
  OS_SCHOOL_METHOD_PARA_TEXT,
  OS_DELI_QUALITY_HEADER_TEXT,
  OS_DELI_QUALITY_PARA_TEXT,
} from "../constants/pages/OurStory.constant";

class OurStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstContFlag: true,
      secondContFlag: false,
      thirdContFlag: false,
      fourthContFlag: false,
    };
    this.firstContRef = React.createRef();
    this.secondContRef = React.createRef();
    this.thirdContRef = React.createRef();
    this.fourthContRef = React.createRef();
  }

  pageNavigate = (pagePath) => {
    navigate(pagePath);
  };

  applyhoverclass = (containerName) => {
    let _state = {
      firstContFlag: false,
      secondContFlag: false,
      thirdContFlag: false,
      fourthContFlag: false,
    };

    if (containerName === "cont1") {
      _state.firstContFlag = true;
    } else if (containerName === "cont2") {
      _state.secondContFlag = true;
    } else if (containerName === "cont3") {
      _state.thirdContFlag = true;
    } else if (containerName === "cont4") {
      _state.fourthContFlag = true;
    }
    this.setState(_state);
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    /* if (this.firstContRef.current.getBoundingClientRect().width > 992) {
      return true;
    } */
    let _state = {
      firstContFlag: false,
      secondContFlag: false,
      thirdContFlag: false,
      fourthContFlag: false,
    };
    if (
      this.firstContRef.current.getBoundingClientRect().top <
      this.firstContRef.current.getBoundingClientRect().height / 1.5 &&
      this.firstContRef.current.getBoundingClientRect().top >
      -this.firstContRef.current.getBoundingClientRect().height / 1.5
    ) {
      _state.firstContFlag = true;
    } else if (
      this.secondContRef.current.getBoundingClientRect().top <
      this.firstContRef.current.getBoundingClientRect().height / 1.5 &&
      this.secondContRef.current.getBoundingClientRect().top >
      -this.firstContRef.current.getBoundingClientRect().height / 1.5
    ) {
      _state.secondContFlag = true;
    } else if (
      this.thirdContRef.current.getBoundingClientRect().top <
      this.firstContRef.current.getBoundingClientRect().height / 1.5 &&
      this.thirdContRef.current.getBoundingClientRect().top >
      -this.firstContRef.current.getBoundingClientRect().height / 1.5
    ) {
      _state.thirdContFlag = true;
    } else if (
      this.fourthContRef.current.getBoundingClientRect().top <
      this.firstContRef.current.getBoundingClientRect().height / 1.5 &&
      this.fourthContRef.current.getBoundingClientRect().top >
      -this.firstContRef.current.getBoundingClientRect().height / 1.5
    ) {
      _state.fourthContFlag = true;
    }
    this.setState(_state);
  };

  render() {
    const {
      firstContFlag,
      secondContFlag,
      thirdContFlag,
      fourthContFlag,
    } = this.state;
    return (
      <Layout
          metaTitle="Our Story | Galileo® Salame"
          metaDescription="Since 1910, we have used our proprietary spice recipe and artisan craftsmanship to bring authentic Italian taste to Galileo® Salame and Pepperoni. Learn about our history."
      >
        <section className="gagl-osgroup-wrapper">
          <div
            style={{ backgroundImage: `url(${os_bg_one})` }}
            className={
              firstContFlag
                ? "gagl-mobile-hover gagl-osgroup-container1"
                : "gagl-osgroup-container gagl-osgroup-container1"
            }
            role="img"
            aria-label="original storefront"
            ref={this.firstContRef}
          >
            <Row className="m-0 gagl-gradient-color">
              <Col className="gagl-osgroup-content">
                <h2 className="gagl-osgroup-header-text">
                  {/* {OS_GALILEO_STORY_HEADER_TEXT} */}
                  THE GALILEO<sup className="gagl-osgroup-sup-text">®</sup>{" "}
                  SALAME STORY
                </h2>
                <div className="gagl-osgroup-grayLine"></div>
                <div className="gagl-osgroup-grayLine"></div>
                <p className="gagl-osgroup-para-text">
                  {/* {OS_GALILEO_STORY_PARA_TEXT} */}
                  Started in 1945 by Frank Sorba in the San Francisco Bay Area, Galileo<sup gagl-para-sup-text>®</sup> Salame was founded on tradition. More than 75 years later, we have stayed true to the taste and quality that made us a success. We still craft our Italian meats in the Bay Area where the temperate climate, similar to that of northern Italy, makes the location ideal to cure salame and develop its deliciously tangy flavor profile.
                </p>
              </Col>
            </Row>
          </div>

          <div
            style={{ backgroundImage: `url(${os_bg_two})` }}
            className={
              secondContFlag
                ? "gagl-mobile-hover gagl-osgroup-container2"
                : "gagl-osgroup-container gagl-osgroup-container2"
            }
            role="img"
            aria-label="sliced salame"
            ref={this.secondContRef}
          >
            <Row className="m-0 gagl-gradient-color">
              <Col className="gagl-osgroup-content">
                <h2 className="gagl-osgroup-header-text">
                  {OS_FLAVOR_SAVOR_HEADER_TEXT}
                </h2>
                <div className="gagl-osgroup-grayLine"></div>
                <div className="gagl-osgroup-grayLine"></div>
                <p className="gagl-osgroup-para-text">
                  {OS_FLAVOR_SAVOR_PARA_TEXT}
                </p>
              </Col>
            </Row>
          </div>

          <div
            className={
              thirdContFlag
                ? "gagl-mobile-hover gagl-osgroup-container3"
                : "gagl-osgroup-container gagl-osgroup-container3"
            }
            ref={this.thirdContRef}
            style={{ backgroundImage: `url(${os_bg_three})` }}
            role="img"
            aria-label="hanging salame"
          >
            <Row className="m-0 gagl-gradient-color">
              <Col className="gagl-osgroup-content">
                <h2 className="gagl-osgroup-header-text">
                  {OS_SCHOOL_METHOD_HEADER_TEXT}
                </h2>
                <div className="gagl-osgroup-grayLine"></div>
                <div className="gagl-osgroup-grayLine"></div>
                <p className="gagl-osgroup-para-text">
                  {OS_SCHOOL_METHOD_PARA_TEXT}
                </p>
              </Col>
            </Row>
          </div>

          <div
            className={
              fourthContFlag
                ? "gagl-mobile-hover gagl-osgroup-container4"
                : "gagl-osgroup-container gagl-osgroup-container4"
            }
            style={{ backgroundImage: `url(${os_bg_four})` }}
            ref={this.fourthContRef}
            role="img"
            aria-label="deli storefront"
          >
            <Row className="m-0 gagl-last-cont-gradient">
              <Col className="gagl-osgroup-content">
                <h2 className="gagl-osgroup-header-text">
                  {OS_DELI_QUALITY_HEADER_TEXT}
                </h2>
                <div className="gagl-osgroup-grayLine"></div>
                <div className="gagl-osgroup-grayLine"></div>
                <p className="gagl-osgroup-para-text">
                  {OS_DELI_QUALITY_PARA_TEXT}
                </p>
                <div className="text-center w-100">
                  <div className="gagl-os-product">
                    <img className="gagl-os-lftimg" src={salame}
                      onClick={() => {
                        this.pageNavigate(
                          "/our-products/sliced-italian-dry-salame/"
                        );
                      }} alt="italian dry salame" />
                    <img className="gagl-os-rgtimg" src={pepperoni_salame}
                      onClick={() => {
                        this.pageNavigate("/our-products/sliced-pepperoni/");
                      }} alt="pepperoni" />
                  </div>
                  <div className="text-center">
                    <button className="gagl-osgroup-btn gagl-btn gagl-btn-red mt-5 mb-3">
                      <Link to="/where-to-buy/">Where To Buy</Link>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </Layout>
    );
  }
}

export default OurStory;
